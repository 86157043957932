import './style.scss'

const calculateImageSize = (image) => {
    const width = image.clientWidth
    const height = image.clientHeight
    return { width, height }
}

const loadImage = (image) => {
    let src = image.getAttribute('data-iod-src')
    if (src) {
        const { width, height } = calculateImageSize(image)
        src += `&width=${width}c&height=${height}c`
        image.classList.add('iod-loading')
        fetch(src)
            .then((response) => response.json())
            .then((data) => {
                image.src = data.image
                image.onload = () => {
                    image.classList.remove('iod-loading')
                    image.classList.add('iod-loaded')
                }
                image.onerror = () => {
                    // Handle errors, possibly setting a fallback image
                }
            })
            .catch((e) => {
                // Handle fetch errors
            })
    }
}

const observer = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                observer.unobserve(entry.target)
                loadImage(entry.target)
            }
        })
    },
    {
        rootMargin: '20% 0% 20% 0%',
        threshold: 0,
    }
)

const iod = () => {
    const images = document.querySelectorAll('img[data-iod-src]')
    images.forEach((image) => {
        observer.observe(image)
    })
}

const DOMReady = (callback) => {
    if (document.readyState !== 'loading') {
        callback()
    } else {
        document.addEventListener('DOMContentLoaded', callback)
    }
}

DOMReady(() => {
    iod()
})
